<template>
  <div v-if="saleAddonsList.length > 0" class="insert-invoice-select-sale-addon">
    <div class="sale-addon-items">
      <template v-for="(addon, key) in saleAddonsList">
        <div :key="key" class="addon-item">
          <div class="addon-name">
            {{addon.name}}
            <span v-if="addon.required" class="text-danger">
              {{ $t('sales.invoices.labels.required') }}
            </span>
          </div>
          <div class="addon-quantity">
            <button class="addon-quantity-insert" :disabled="handleDisableIncreaseQuantity(key)" @click="handleIncreaseQuantity(key)">
              <custom-icon icon="PLUS"
                           height="20px"
                           width="20px"
                           color-code="#ffffff" />
            </button>
            <div class="addon-count">
              {{addon.quantity}}
            </div>

            <button class="addon-quantity-decrease" :disabled="handleDisableDecreaseQuantity(key)" @click="handleDecreaseQuantity(key)">
              <!--              <custom-icon icon="PLUS"-->
              <!--                           height="20px"-->
              <!--                           width="20px"-->
              <!--                           color-code="#ffffff" />-->
              <img src="https://img.icons8.com/material-outlined/24/ffffff/minus.png" height="20px" width="20px"/>
            </button>
          </div>
          <div class="addon-price">{{ addComma(addon.finalPrice.value) || 0 }} {{ $locale.currency() }}</div>
        </div>
      </template>
    </div>

    <button id="insertInvoiceSaleAddonsBTN" class="useral-action-button" @click="sendData"></button>

  </div>
</template>

<script>
  import CustomSelect from "../../../../../components/customSelect/customSelect";
  import {getUserSaleAddons} from "../../../../../http/requests/users/users";
  import {addComma, hideLoading, showLoading} from "../../../../../assets/js/functions";
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  export default {
    name: "selectSaleAddons",
    components: {CustomIcon, CustomSelect},
    props: {
      userId: {
        type: Number,
        default: 0
      },
      realTime: {
        type: Boolean,
        default: false
      },
      selectedSaleAddons: {
        type: Array,
        default: () => { return [] }
      },
      courtPrice: {
        type: Number,
        default: () => { return 0 }
      }
    },
    data () {
      return {
        saleAddons: [],
        saleAddonsList: [],
        saleAddonValues: [
          {
            label: '-',
            value: 0
          }
        ],
        selectedSaleAddonValue: {},
      }
    },
    computed: {
      computeTotalPrice () {
        let price = this.courtPrice
        if (this.saleAddonsList.length > 0) {
          this.saleAddonsList.forEach((item) => {
            price += parseInt(item.finalPrice.value.toString().split(',').join(''))
          })
        }
        return addComma(price)
      }
    },
    created () {
      showLoading()
      this.getUserSaleAddons()
    },
    methods: {
      addComma (val) {
        return addComma(val)
      },
      handleDecreaseQuantity (index) {
        const addon = JSON.parse(JSON.stringify(this.saleAddonsList[index]))
        if (addon.quantity > 0) {
          this.saleAddonsList[index].quantity -= 1
        }
        this.saleAddonsList[index].finalPrice.value = (parseInt(this.saleAddonsList[index].unitPrice.value) * this.saleAddonsList[index].quantity).toString()
        if (this.realTime) {
          this.sendData()
        }
      },
      handleIncreaseQuantity (index) {
        const addon = JSON.parse(JSON.stringify(this.saleAddonsList[index]))
        if (addon.quantity < (addon.maxSelect || 100)) {
          this.saleAddonsList[index].quantity += 1
        }
        this.saleAddonsList[index].finalPrice.value = (parseInt(this.saleAddonsList[index].unitPrice.value) * this.saleAddonsList[index].quantity).toString()
        if (this.realTime) {
          this.sendData()
        }
      },
      handleDisableDecreaseQuantity (index) {
        const addon = JSON.parse(JSON.stringify(this.saleAddonsList[index]))
        return addon.quantity === 0;
      },
      handleDisableIncreaseQuantity (index) {
        const addon = JSON.parse(JSON.stringify(this.saleAddonsList[index]))
        return addon.quantity === addon.maxSelect;
      },
      getUserSaleAddons () {
        getUserSaleAddons(this.userId).then(response => {
          this.saleAddons = []
          const saleAddons = response.data.data
          if (saleAddons && saleAddons.length > 0) {
            saleAddons.forEach(saleAddon => {
              this.saleAddons.push({
                id: saleAddon.id,
                name: saleAddon.name || '',
                price: saleAddon.price || 0,
                maxSelect: saleAddon.max_select,
                required: saleAddon.required
              })
              if (!saleAddon.required) {
                this.saleAddonValues.push({
                  id: saleAddon.id,
                  label: saleAddon.name,
                  value: saleAddon.id,
                })
              }
            })
          }
          if (this.selectedSaleAddons.length > 0) {
            this.saleAddonsList = JSON.parse(JSON.stringify(this.selectedSaleAddons))
            this.getSaleAddons()
          } else {
            setTimeout(() => {
              this.getSaleAddons()
            }, 200)
          }
          hideLoading()
          if (this.saleAddons.length > 0 || this.saleAddonsList.length > 0) {
            this.$emit('hasSaleAddon')
          }
        })
          .catch(() => {
            hideLoading()
          })
      },
      getSaleAddons () {
        if (this.saleAddons.length > 0) {
          this.saleAddons.forEach(item => {
            const addonIndex = this.saleAddonsList.map(e => e.id).indexOf(item.id)
            if (addonIndex === -1) {
              this.saleAddonsList.push({
                rowNumber: this.saleAddonsList.length + 1,
                id: item.id,
                name: item.name,
                quantity: 0,
                maxSelect: item.max_select,
                unitPrice: {
                  value: item.price,
                  type: 'price'
                },
                finalPrice: {
                  type: 'price',
                  value: 0
                },
                required: item.required
              })
            } else {
              this.saleAddonsList[addonIndex].maxSelect = item.maxSelect
            }
          })
        }
        hideLoading()
      },
      sendData () {
        let error_num = 0
        let addons = []
        this.saleAddonsList.forEach(item => {
          if (item.quantity >= 0) {
            addons.push(item)
          }
          if (item.quantity > item.maxSelect) {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('sales.invoices.notifications.maxAddon', {name: item.name, count: item.maxSelect}),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
            error_num++
          }
        })
        if (error_num === 0) {
          this.$emit('inserted', addons)
        }
      }
    },
    watch: {
      userId: {
        handler () {
          this.getUserSaleAddons()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .insert-invoice-select-sale-addon {
    $primary: #62c1e6;

    height: 100%;
    /*padding-top: 15px;*/
    display: flex;
    flex-direction: column;

    .sale-addon-items {
      flex-grow: 1;
      overflow-y: auto;
      height: calc(100% - 70px);

      &::-webkit-scrollbar {
        display: block;
        border-radius: .2rem;
        width: 10px;
        background: #f9f9fd;
        right: 10px;
        margin-left: 10px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: .2rem;
        background: #cecece;
      }

      .addon-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .addon-name {
          width: 45%;
        }

        .addon-quantity {
          width: 25%;
          display: flex;
          align-items: center;
          text-align: center;

          .addon-count {
            width: 30px;
          }

          .addon-quantity-insert {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            width: 25px;
            margin-right: 10px;
            border-radius: 50%;
            border: 1px solid $primary;
            background-color: $primary;

            &:hover {
              background-color: #57a9c8;
              border: 1px solid #57a9c8;
              cursor: pointer;
            }

            .icon-component {
              position: relative;
              left: -2px;
            }
          }

          .addon-quantity-decrease {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            width: 25px;
            margin-left: 10px;
            border: 1px solid grey;
            border-radius: 50%;
            background-color: grey;

            &:hover {
              background-color: #626262;
              cursor: pointer;
            }
          }

          .addon-quantity-insert:disabled {
            background-color: #b3eaff;
            border: 1px solid #b3eaff;
          }

          .addon-quantity-decrease:disabled {
            background-color: #cdcdcd;
            border: 1px solid #cdcdcd;
          }
        }

        .addon-price {
          width: 30%;
          text-align: right;
        }
      }
    }

    .addon-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 0.5rem;
      height: 50px;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 0.5rem;
    }

    .insert-attribute-btn {
      font-size: 18px;
      position: absolute;
      right: 3px;
      top: -10px;
      bottom: 0;
      margin: auto;
      height: fit-content;
      padding: 6px;
      border-radius: .5rem;
      border: 1px solid #cecece;
      background: #cecece54;
      cursor: pointer;
      z-index: 200;
      transition: all .3s ease;

      &:hover {
        box-shadow: 0 0 10px #cecece;
      }
    }

    .sale-addons-table {
      height: calc(100% - 47px);
    }
  }
</style>
